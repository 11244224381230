<template>
<div class="row tgju-widgets-row">
    <slot v-if="widgetLoad">
        <div class="text-center p-2">
            <div class="widget-loading"></div>
        </div>
    </slot>
    <slot v-else>
        
        <div class="tgju-widgets-block col-md-12 col-lg-12 col-xl-4">
                <div class="tgju-widgets-block">
                    <div class="tgju-widget light has-icon first-block-main-tab">
                        <h2 class="tgju-widget-title dot m-0">
                            {{ market_profile.info.title }} در یک نگاه
                            <span class="tgju-widget-up-i">{{ market_profile.info.time }} <i class="fa fa-clock-o" aria-hidden="true"></i></span>
                        </h2>

                        <div class="tables-default normal">
                            <table class="table table-hover text-center">
                                <thead>
                                    <tr>
                                        <th class="text-right" style="padding: 13px 20px 13px 15px !important;">خصیصه</th>
                                        <th class="text-left" style="padding: 13px 15px 13px 20px !important;">مقادیر</th>
                                    </tr>
                                </thead>
                                <tbody class="table-padding-lg">
                                    <tr>
                                        <td class="text-right">{{ market_profile.summary.price.title }}</td>
                                        <td class="text-left" v-html="market_profile.summary.price.value"></td>
                                    </tr>
                                    <tr v-if="market_profile.summary.irr_price">
                                        <td class="text-right">{{ market_profile.summary.irr_price.title }}</td>
                                        <td class="text-left" v-html="market_profile.summary.irr_price.value"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-right">{{ market_profile.summary.high.title }}</td>
                                        <td class="text-left"><span v-html="market_profile.summary.high.value"></span></td>
                                    </tr>
                                    <tr>
                                        <td class="text-right">{{ market_profile.summary.low.title }}</td>
                                        <td class="text-left"><span v-html="market_profile.summary.low.value"></span></td>
                                    </tr>
                                    <tr>
                                        <td class="text-right">{{ market_profile.summary.day_tolerance_d.title }}</td>
                                        <td class="text-left" v-html="market_profile.summary.day_tolerance_d.value"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-right">{{ market_profile.summary.day_tolerance_dp.title }}</td>
                                        <td class="text-left" v-html="market_profile.summary.day_tolerance_dp.value"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-right">{{ market_profile.summary.open.title }}</td>
                                        <td class="text-left" v-html="market_profile.summary.open.value"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-right">{{ market_profile.summary.time.title }}</td>
                                        <td class="text-left" v-html="market_profile.summary.time.value"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-right">{{ market_profile.summary.yesterday_price.title }}</td>
                                        <td class="text-left" v-html="market_profile.summary.yesterday_price.value"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-right">{{ market_profile.summary.yesterday_dp.title }}</td>
                                        <td class="text-left" v-html="market_profile.summary.yesterday_dp.value"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-right">{{ market_profile.summary.yesterday_d.title }}</td>
                                        <td class="text-left" v-html="market_profile.summary.yesterday_d.value"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <router-link :to="{ name: 'MarketToday', params: { name: market_profile.info.item_id },}" class="l-item-btn-color-blue">
                            <div class="tgju-widget-more-list">مشاهده لیست شاخص در روز جاری <i class="fa fa-chevron-left" aria-hidden="true"></i></div>
                        </router-link>
                        <!-- <a :href="`profile/${ market_profile.info.item_id }/today`" class="tgju-widget-more-list">مشاهده لیست شاخص در روز جاری <i class="fa fa-chevron-left" aria-hidden="true"></i></a> -->
                    </div>
                </div>
                <div class="tgju-widgets-block">
                    <div class="tgju-widget light has-icon t-mb-2">
                        <h2 class="tgju-widget-title dot m-0">مقایسه‌گر سرمایه‌گذاری در نفت برنت</h2>


                        <div class="tgju-widget-content tgju-widget-forms">
                            <select id="invest_select" @change="onChange" v-model="invest_select">
                                <option value="1">1 روز</option>
                                <option value="6">1 هفته</option>
                                <option value="24">1 ماه</option>
                                <option value="72" selected="selected">3 ماه</option>
                                <option value="144">6 ماه</option>
                                <option value="188">1 سال</option>
                            </select>

                            <p style="padding-top: 5px;text-align: center;">
                                مبلغ سرمایه خود را وارد نمایید تا نتیجه مقایسه آن با سایر بازار ها در مقادیر فعلی جایگزین شود
                            </p>
                            <input type="text" @input="someHandler($event)" class="invest_others_input" value="" placeholder="مقدار سرمایه خود را به ریال وارد کنید" style="min-width: 180px; text-align: center">
                        </div>

                    </div>

                    <div  class="row" style="padding: 0 5px;" >
                        <div class="col-6 col-md-6 mb-6 invest-item" v-for="(item, index) in invest_items" :data-key=" index" :key="index">
                            <div class="tgju-widgets-block" style="overflow: hidden;margin-bottom: 14px;">
                                <div class="tgju-widget light has-icon">
                                    <div class="tables-data tables-border-bt-none">
                                        <table class="table">
                                            <thead class="text-center">
                                                <tr>
                                                    <th>{{ item }}</th>
                                                </tr>
                                            </thead>
                                            <tbody class="table-padding-lg">
                                                <tr>
                                                    <td class="padding-set-num">{{ formatPrice(market_profile.invest[index][72]['value']) }}<span class="currency-type"> {{ market_profile.invest[index][72]['currency'] }}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tgju-widget light has-icon">
                        <h3 class="m-0">
                            <router-link :to="{ name: 'MarketProfitloss', params: { name: market_profile.info.item_id },}" class="tgju-widget-more-list"  style="font-size: 12px;">
                                مشاهده سایر ابزارهای سرمایه گذاری {{ market_profile.info.title }} 
                                <i class="fa fa-chevron-left" aria-hidden="true"></i>
                            </router-link>
                        </h3>
                    </div>
                </div>
        </div>

        <div class="tgju-widgets-block col-md-12 col-lg-12 col-xl-8" >
            <div class="row" style="margin: 0px -10px 0 ;">
                <div class="tgju-widgets-block col-12 col-md-12 col-lg-6">
                    <div class="tgju-widget light has-icon">
                        <h2 class="tgju-widget-title dot m-0"> نوسانات {{ market_profile.info.title }} در روز جاری</h2>
                        <HighCharts :cssStyle="'width: 100%; height: 260px; margin: 0 auto;margin-top: -10px;'" :options="chart_block_0_options" v-if="chart_block_0_options"></HighCharts>
                        <!-- <div id="ChartBlock-0" style="width: 100%; height: 240px; margin: 0 auto;margin-top: -10px;" ref="ChartBlock-0"></div> -->
                    </div> 
                </div>

                <div class="tgju-widgets-block col-12 col-md-12 col-lg-6 profile-performance-box">
                    <div class="tgju-widget light has-icon">
                        <h2 class="tgju-widget-title dot m-0"> عملکرد {{ market_profile.info.title }}</h2>
                        <div class="tables-default normal ">
                            <table class="table table-hover text-center">
                                <tbody>
                                    <tr>
                                        <td class="text-tbl-first">یک هفته</td>
                                        <td><span :class="`${ category_performance['1_week'].dt }`" dir="ltr">{{ category_performance['1_week'].dt ? formatPrice(category_performance['1_week'].d) : '-' }}</span></td>
                                        <td><span :class="`${ category_performance['1_week'].dt } no-arrow`" dir="ltr">{{ category_performance['1_week'].dt ? formatPrice(category_performance['1_week'].dp) + '%' : '-' }}</span></td>
                                        <td>{{ category_performance['1_week'].dt ? to_yyyy_mm_dd(category_performance['1_week'].past_date) : '-' }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-tbl-first">یک ماه</td>
                                        <td><span :class="`${ category_performance['1_month'].dt }`" dir="ltr">{{ category_performance['1_month'].dt ? formatPrice(category_performance['1_month'].d) : '-' }}</span></td>
                                        <td><span :class="`${ category_performance['1_month'].dt } no-arrow`" dir="ltr">{{ category_performance['1_month'].dt ? formatPrice(category_performance['1_month'].dp) + '%' : '-' }}</span></td>
                                        <td>{{ category_performance['1_month'].dt ? to_yyyy_mm_dd(category_performance['1_month'].past_date) : '-' }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-tbl-first">شش ماه</td>
                                        <td><span :class="`${ category_performance['6_months'].dt }`" dir="ltr">{{ category_performance['6_months'].dt ? formatPrice(category_performance['6_months'].d) : '-' }}</span></td>
                                        <td><span :class="`${ category_performance['6_months'].dt } no-arrow`" dir="ltr">{{ category_performance['6_months'].dt ? formatPrice(category_performance['6_months'].dp) + '%' : '-' }}</span></td>
                                        <td>{{ category_performance['6_months'].dt ? to_yyyy_mm_dd(category_performance['6_months'].past_date) : '-' }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-tbl-first">یک سال</td>
                                        <td><span :class="`${ category_performance['1_year'].dt }`" dir="ltr">{{ category_performance['1_year'].dt ? formatPrice(category_performance['1_year'].d) : '-' }}</span></td>
                                        <td><span :class="`${ category_performance['1_year'].dt } no-arrow`" dir="ltr">{{ category_performance['1_year'].dt ? formatPrice(category_performance['1_year'].dp) + '%' : '-' }}</span></td>
                                        <td>{{ category_performance['1_year'].dt ? to_yyyy_mm_dd(category_performance['1_year'].past_date) : '-' }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-tbl-first">سه سال</td>
                                        <td><span :class="`${ category_performance['3_years'].dt }`" dir="ltr">{{ category_performance['3_years'].dt ? formatPrice(category_performance['3_years'].d) : '-' }}</span></td>
                                        <td><span :class="`${ category_performance['3_years'].dt } no-arrow`" dir="ltr">{{ category_performance['3_years'].dt ? formatPrice(category_performance['3_years'].dp) + '%' : '-' }}</span></td>
                                        <td>{{ category_performance['3_years'].dt ? to_yyyy_mm_dd(category_performance['3_years'].past_date) : '-' }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <router-link :to="{ name: 'MarketPerformance', params: { name: market_profile.info.item_id },}" class="l-item-btn-color-blue">
                            <div class="tgju-widget-more-list">مشاهده لیست کامل عملکرد <i class="fa fa-chevron-left" aria-hidden="true"></i></div>
                        </router-link>
                        <!-- <a :href="`profile/${ market_profile.info.item_id }/performance`" class="tgju-widget-more-list">مشاهده لیست کامل عملکرد
                            <i class="fa fa-chevron-left" aria-hidden="true"></i>
                        </a> -->
                    </div>
                </div>

                <div class="tgju-widgets-block col-12 col-md-12 col-lg-6 profile-indicator">
                    <div class="tgju-widget light has-icon">
                        <h2 class="tgju-widget-title dot m-0">
                            ‌میانگین متحرک (EMA) {{ market_profile.info.title }}
                        </h2>
                        <div class="tables-data tables-border-bt-none table-mobile-grid">
                            <table class="table">
                                <thead class="text-center">
                                    <tr>
                                        <th>اندیکاتور</th>
                                        <th>بازه زمانی</th>
                                        <th>مقدار</th>
                                    </tr>
                                </thead>
                                <tbody class="table-padding-lg">
                                    <tr>
                                        <td class="text-tbl-first">میانگین متحرک نمایی (EMA)</td>
                                        <td>5 روز</td>
                                        <td>{{ formatPrice(market_profile.indicators.indicator_values.ema[5]) }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-tbl-first">میانگین متحرک نمایی (EMA)</td>
                                        <td>10 روز</td>
                                        <td>{{ formatPrice(market_profile.indicators.indicator_values.ema[10]) }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-tbl-first">میانگین متحرک نمایی (EMA)</td>
                                        <td>20 روز</td>
                                        <td>{{ formatPrice(market_profile.indicators.indicator_values.ema[20]) }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-tbl-first">میانگین متحرک نمایی (EMA)</td>
                                        <td>30 روز</td>
                                        <td>{{ formatPrice(market_profile.indicators.indicator_values.ema[30]) }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-tbl-first">میانگین متحرک نمایی (EMA)</td>
                                        <td>50 روز</td>
                                        <td>{{ formatPrice(market_profile.indicators.indicator_values.ema[50]) }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-tbl-first">میانگین متحرک نمایی (EMA)</td>
                                        <td>100 روز</td>
                                        <td>{{ formatPrice(market_profile.indicators.indicator_values.ema[100]) }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-tbl-first">میانگین متحرک نمایی (EMA)</td>
                                        <td>200 روز</td>
                                        <td>{{ formatPrice(market_profile.indicators.indicator_values.ema[200]) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="tgju-widgets-block col-12 col-md-12 col-lg-6 profile-indicator">
                    <div class="tgju-widget light has-icon">
                        <h2 class="tgju-widget-title dot m-0">
                        ‌میانگین متحرک (SMA) {{ market_profile.info.title }}
                        </h2>
                        <div class="tables-data tables-border-bt-none table-mobile-grid">
                            <table class="table">
                                <thead class="text-center">
                                    <tr>
                                        <th>اندیکاتور</th>
                                        <th>بازه زمانی</th>
                                        <th>مقدار</th>
                                    </tr>
                                </thead>
                                <tbody class="table-padding-lg">
                                    <tr>
                                        <td class="text-tbl-first">میانگین متحرک ساده (SMA)</td>
                                        <td>5 روز</td>
                                        <td>{{ formatPrice(market_profile.indicators.indicator_values.sma[5]) }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-tbl-first">میانگین متحرک ساده (SMA)</td>
                                        <td>10 روز</td>
                                        <td>{{ formatPrice(market_profile.indicators.indicator_values.sma[10]) }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-tbl-first">میانگین متحرک ساده (SMA)</td>
                                        <td>20 روز</td>
                                        <td>{{ formatPrice(market_profile.indicators.indicator_values.sma[20]) }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-tbl-first">میانگین متحرک ساده (SMA)</td>
                                        <td>30 روز</td>
                                        <td>{{ formatPrice(market_profile.indicators.indicator_values.sma[30]) }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-tbl-first">میانگین متحرک ساده (SMA)</td>
                                        <td>50 روز</td>
                                        <td>{{ formatPrice(market_profile.indicators.indicator_values.sma[50]) }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-tbl-first">میانگین متحرک ساده (SMA)</td>
                                        <td>100 روز</td>
                                        <td>{{ formatPrice(market_profile.indicators.indicator_values.sma[100]) }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-tbl-first">میانگین متحرک ساده (SMA)</td>
                                        <td>200 روز</td>
                                        <td>{{ formatPrice(market_profile.indicators.indicator_values.sma[200]) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="tgju-widgets-block col-12 col-md-12 col-lg-12 profile-indicator-resistance">
                    <div class="tgju-widget light has-icon">
                        <h2 class="tgju-widget-title dot m-0">
                            سطوح ‌حمایت و مقاومت {{ market_profile.info.title }}
                        </h2>
                        <div class="tables-data tables-border-bt-none table-mobile-grid">
                            <table class="table dataTable">
                                <thead class="text-center">
                                    <tr>
                                        <th>سطوح</th>
                                        <th>کلاسیک (Classic)</th>
                                        <th>فیبوناچی (Fibonacci)</th>
                                        <th>کاماریلا (Camarilla)</th>
                                        <th>وودی (Woodie)</th>
                                        <th>دی مارک (DM)</th>
                                    </tr>
                                </thead>
                                <tbody class="table-padding-lg">
                                    <tr>
                                        <td class="text-tbl-first">حمایت 3 (S3)</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.classic.s3) }}</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.fibonacci.s3) }}</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.camarilla.s3) }}</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.woodie.s3) }}</td>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td class="text-tbl-first">حمایت 2 (S2)</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.classic.s2) }}</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.fibonacci.s2) }}</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.camarilla.s2) }}</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.woodie.s2) }}</td>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td class="text-tbl-first">حمایت 1 (S1)</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.classic.s1) }}</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.fibonacci.s1) }}</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.camarilla.s1) }}</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.woodie.s1) }}</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.demark.s1) }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-tbl-first">نقطه پیوت (PP)</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.classic.pp) }}</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.fibonacci.pp) }}</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.camarilla.pp) }}</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.woodie.pp) }}</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.demark.pp) }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-tbl-first">مقاومت 1 (R1)</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.classic.r1) }}</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.fibonacci.r1) }}</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.camarilla.r1) }}</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.woodie.r1) }}</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.demark.r1) }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-tbl-first">مقاومت 2 (R2)</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.classic.r2) }}</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.fibonacci.r2) }}</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.camarilla.r2) }}</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.woodie.r2) }}</td>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td class="text-tbl-first">مقاومت 3 (R3)</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.classic.r3) }}</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.fibonacci.r3) }}</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.camarilla.r3) }}</td>
                                        <td>{{ formatPrice(market_profile.indicators.support_and_resistance.woodie.r3) }}</td>
                                        <td>-</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-md-12 col-lg-12">
            <flickity class="news-slider-box" :options="flickityOptions">
                <div v-for="(slider, index) in special_news.items.data" :key="index" class="news-slider-box-item">
                    <figure class="news-slider-box-item-cover">
                        <a :href="`/news/${ slider.id }/${ slider.slug }`" class="link-list-flex-image link-title" target="_blank">
                            <img class="news-slider-box-item-img" :src="slider.image ? slider.image : 'https://static.tgju.org/views/default/images/news/no-img.png}'" :alt=slider.title>
                        </a>
                    </figure>
                    <div class="news-slider-box-item-content">
                        <h3>
                            <a :href="`/news/${ slider.id }/${ slider.slug }`" target="_blank">{{ slider.title }}</a>
                        </h3>
                        <div class="news-slider-box-item-time">
                            {{ toRelative(slider.created_at) }}
                        </div>
                    </div>
                </div>
            </flickity>
        </div>

        <div class="tgju-widgets-block col-12 col-md-12 col-lg-6 mobile-hide" >
            <div class="tgju-widget light has-icon">
                <h2 class="tgju-widget-title dot m-0"> نمودار کندل‌استیک  {{ market_profile.info.title }} در روز جاری</h2>
                <HighCharts :options="chart_block_1_options" v-if="chart_block_1_options"></HighCharts>
                <!-- <div id="ChartBlock-1" style="width: 100%; height: 300px; margin: 0 auto;margin-top: -10px;"></div> -->
            </div>
        </div>

        <div class="tgju-widgets-block col-12 col-md-12 col-lg-6 mobile-hide">
            <div class="tgju-widget light has-icon">
                <h2 class="tgju-widget-title dot m-0"> نمودار نوسان  {{ market_profile.info.title }} در همه ادوار</h2>
                <HighCharts :options="chart_block_3_options" v-if="chart_block_3_options"></HighCharts>
                <!-- <div id="ChartBlock-3" style="width: 100%; height: 300px; margin: 0 auto;margin-top: -10px;"></div> -->
            </div>
        </div>

        <div class="tgju-widgets-block col-12 col-md-12 col-lg-12" style="padding:0 !important;">
            <section class="faq-accordion row"  style="margin: 0px;" itemscope="" itemprop="mainEntity" itemtype="https://schema.org/FAQPage">
                <div class="tgju-widgets-block col-12 col-md-12 col-lg-6">
                    <div class="tgju-widget light has-icon">
                        <h2 class="tgju-widget-title dot m-0"> گزارشات  {{ market_profile.info.title }} </h2>
                            <div v-for="(item, index) in content_market" :key="index" v-if="index%2 == 0"  @click="toggleMe" class="card" itemscope="" itemprop="mainEntity" itemtype="https://schema.org/Question">
                                <h3 itemprop="name" class="title-faq"><i class="fa fa-plus-square-o"></i> {{ item.title }}</h3>
                                <div class="answer-faq" itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <div itemprop="text" v-html="item.body">
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                <div class="tgju-widgets-block col-12 col-md-12 col-lg-6">
                    <div class="tgju-widget light has-icon">
                        <h2 class="tgju-widget-title dot m-0"> گزارشات  {{ market_profile.info.title }} </h2>
                            <div v-for="(item, index) in content_market" :key="index" v-if="index%2 == 1" @click="toggleMe" class="card" itemscope="" itemprop="mainEntity" itemtype="https://schema.org/Question">
                                <h3 itemprop="name" class="title-faq"><i class="fa fa-plus-square-o"></i> {{ item.title }}</h3>
                                <div class="answer-faq" itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <div itemprop="text" v-html="item.body">
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="tgju-widgets-block col-12 col-md-6 mobile-hide">
            <div class="tgju-widget light has-icon t-mb-2">
                <h2 class="tgju-widget-title dot m-0"> آرشیو قیمت‌های {{ market_profile.info.title }}</h2>

                <div class="tables-default normal">
                    <table class="table table-hover text-center">
                        <thead class="text-center">
                            <tr>
                                <th>تاریخ</th>
                                <th>بازگشایی</th>
                                <th>کمترین</th>
                                <th>بیشترین</th>
                                <th>پایانی</th>
                                <th>درصد تغییر</th>
                                <th>میزان تغییر</th>
                            </tr>
                        </thead>
                        <tbody class="table-padding-lg">
                            <tr v-for="(item, index) in market_profile.summary_table" :key="index">
                                <td>{{ item.jalali_date }}</td>
                                <td>{{ item.open }}</td>
                                <td>{{ item.min }}</td>
                                <td>{{ item.max }}</td>
                                <td>{{ item.price }}</td>
                                <td v-if="item.dp != '-'">
                                    <span :class="`${item.dt}`" dir="ltr" v-html="formatPrice(item.dp) + '%'"></span>
                                </td>
                                <td v-else>
                                    -
                                </td>
                                <td><span :class="`${ item.dt }`" dir="ltr">{{ formatPrice(item.d) }}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <router-link :to="{ name: 'MarketHistory', params: { name: market_profile.info.item_id },}" class="l-item-btn-color-blue">
                    <div class="tgju-widget-more-list">مشاهده لیست کامل تاریخچه <i class="fa fa-chevron-left" aria-hidden="true"></i></div>
                </router-link>
                <!-- <a :href="`profile/${ market_profile.info.item_id }/history`" class="tgju-widget-more-list">
                    مشاهده لیست کامل تاریخچه
                    <i class="fa fa-chevron-left" aria-hidden="true"></i>
                </a> -->
                <div class="stocks-block-icon"><i class="fa fa-file-text"></i></div>
            </div>
        </div>

        <div class="tgju-widgets-block col-12 col-md-6 mobile-hide">
            <div class="tgju-widget light has-icon t-mb-2">
                <h2 class="tgju-widget-title dot m-0">{{ market_profile.info.title }} در روز جاری</h2>
                <table class="table table-hover text-center today-table">
                    <thead class="text-center">
                        <tr>
                            <th>قیمت</th>
                            <th>زمان</th>
                            <th>مقدار تغییر</th>
                            <th>درصد تغییر</th>
                            <th class="text-center bt-0">
                                <span class="clearfix color-gray mt-0">مقدار تغییر نسبت به</span>
                                <div class="clearfix" style="margin-top: 5px;">نرخ روز گذشته</div>
                            </th>
                            <th class="text-center bt-0">
                                <span class="clearfix color-gray mt-0">درصد تغییر نسبت</span>
                                <div class="clearfix" style="margin-top: 5px;">نرخ روز گذشته</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody id="table-list" class="text-center">
                        <tr v-for="(item, index) in market_profile.today_table" :key="index">
                            <td>{{ formatPrice(item.value) }}</td>
                            <td>{{ item.time }}</td>

                            <td><span :class="`${ item.dt }`" dir="ltr">{{ formatPrice(item.d) }}</span></td>
                            <td v-if="item.dp != '-' && item.dp != ''"><span :class="`${item.dt}`" dir="ltr" v-html="formatPrice(item.dp) + '%'"> </span></td><td v-else>-</td>

                            <td><span :class="`${ item.tolerance_yesterday.dt }`" dir="ltr">{{ formatPrice(item.tolerance_yesterday.d) }}</span></td>
                            <td v-if="item.tolerance_yesterday.dp != '-' && item.tolerance_yesterday.dp != ''"><span :class="`${item.tolerance_yesterday.dt}`" dir="ltr" v-html="formatPrice(item.tolerance_yesterday.dp) + '%'"></span></td><td v-else>-</td>
                        </tr>
                    </tbody>
                </table>

                <router-link :to="{ name: 'MarketToday', params: { name: market_profile.info.item_id },}" class="l-item-btn-color-blue">
                    <div class="tgju-widget-more-list">مشاهده لیست کامل شاخص در روز جاری <i class="fa fa-chevron-left" aria-hidden="true"></i></div>
                </router-link>
                <!-- <a :href="`profile/${ market_profile.info.item_id }/today`" class="tgju-widget-more-list">
                    مشاهده لیست کامل شاخص در روز جاری
                    <i class="fa fa-chevron-left" aria-hidden="true"></i>
                </a> -->

                <div class="stocks-block-icon"><i class="fa fa-list"></i></div>
            </div>
        </div>
  </slot>

</div>
</template>

<style lang="scss" scoped>
thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}

.high.dir,
.low.dir {
    max-height: 25px;
    margin: 10px;
}
</style>

<script>
// این کامپوننت دارای اجزای بخش صفحه اول نمایش پروفایل شاخص در بازار ها می باشد
import moment from "moment-jalaali";
import $ from "jquery";

// این کامپوننت دارای اجزای ویجت نمودار ها در ویجت ساز داشبورد می باشد .
import HighCharts from '@/plugins/HighCharts.vue';
import Flickity from 'vue-flickity';
export default {
    name: "MarketProfileComponent",
    components: {
        HighCharts,
        Flickity,
    },
    data: function () {
        return {
            widgetLoad: true,
            market_profile: [],
            invest_items: [],
            content_market: [],
            special_news: [],
            category_performance: [],
            chart_block_0_options: null,
            chart_block_1_options: null,
            chart_block_3_options: null,
            invest_select: 72,
            invest_others_input: 0,
            flickityOptions: {
                autoPlay: true,
                prevNextButtons: false,
                pageDots: false,
                wrapAround: true
            }
        };
    },
    mounted() {
        this.getProfileInformation();
    },
    methods: {
        // این متد برای نمایش زمان به صورت رلتیو می باشد
        time() {
            return moment().format("LTS");
        },
        // نمایش زمان بصورت 1401/03/12
        to_yyyy_mm_dd(date) {
            return moment(date).format("jYYYY/jMM/jDD");
        },
        // این متد جهت نمایش مناسب زمان مورد استفاده قرار میگیرد
        toRelative(date) {
            return moment(date).format("dddd jD jMMMM jYYYY / HH:mm");
        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            if (value === null) {
                return '0';
            }

            value = parseFloat(value);
            return parseFloat(value.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        onChange() {
            this.calculateInvest();
        },
        someHandler(event) {
            this.invest_others_input = event.target.value;
            this.calculateInvest();
        },
        calculateInvest() {
            var base_price = this.invest_others_input;

            var key = this.invest_select
            var main_data = JSON.parse(JSON.stringify(this.market_profile.invest[this.$route.params.name]));

            var main_value;
            if ( base_price !== '' ) {
                main_value = main_data[key + '_base']['value'] * base_price;
            } else {
                main_value = main_data[key]['value'];
            }
            // if ( market_type == 'global' ) { // تبدیل دلار به ریال
            //     main_value = main_value * dollar_price;
            // }

            // if ( base_price !== '' ) {
            //     var this_data = this_item[key+'_base'];
            //     var this_value = this_data['value'];
            //     this_value = this_value * base_price;
            // } else {
            //     var this_data = this_item[key];
            //     var this_value = this_data['value'];
            // }
            for ( var item_key in this.invest_items ) {
                var this_item = this.market_profile.invest[item_key];
                var this_elm = $('.invest-item[data-key="'+item_key+'"]');
                var this_data;
                var this_value;
                if ( base_price !== '' ) {
                    this_data = this_item[key+'_base'];
                    this_value = this_data['value'];
                    this_value = this_value * base_price;
                } else {
                    this_data = this_item[key];
                    this_value = this_data['value'];
                }

                this_elm.find('.padding-set-num').html(this.formatPrice(this_value) + ' <span class="currency-type">' + this_data['currency'] + '</span>');
            }
        },
        // این متد وظیفه دریافت اطلاعات پروفایل شاخص را دارد
        getProfileInformation() {
            this.$helpers
                .makeRequest("GET", `/market/profile-summary/${this.$route.params.name}`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.market_profile = api_response.data.response.detail.response;
                        this.content_market = api_response.data.response.detail.content_market.response;    
                        this.invest_items = api_response.data.response.detail.invest_items;
                        this.special_news = api_response.data.response.detail.special_news.response;
                        this.$parent.title = this.market_profile.info.title + ' - ' + this.$route.meta.title;
                        this.category_performance = this.market_profile.category_performance[this.$route.params.name];
                        this.widgetLoad = false;
                        this.chart_block_0_options = {
                            chartData: JSON.parse(this.market_profile.today_chart),
                            tooltipTitle: 'قیمت امروز',
                            chartType: "areaspline",
                            buttonEnabled: false,
                            markerEnabled: false,
                            gridLineStyle: "longdash",
                            fillOpacity: 0.1,
                            markerLineWidth: 1,
                            yAxisLineColor: "#feeeec",
                            backgroundColor: false,
                            plotColor: "#892731",
                            markerFillColor: "#feeeec",
                            plotShadow: false,
                            tooltipOnlyTime: true,
                            navigatorEnabled: false
                        };

                        this.chart_block_1_options = {
                            chartData: JSON.parse(this.market_profile.today_chart_candle),
                            chartType: "candlestick",
                            chartTitle: "",
                            yAxisTitle: "",
                            buttonEnabled: false,
                            tooltipOnlyTime: true,
                            navigatorEnabled: true,
                            plotColor: "#cb494d",
                            upColor: "#56ac53"
                        };

                        this.chart_block_3_options = {
                            chartData: JSON.parse(this.market_profile.summary_chart),
                            tooltipTitle: 'قیمت',
                            chartType: "areaspline",
                            buttonEnabled: false,
                            markerEnabled: false,
                            gridLineStyle: "longdash",
                            fillOpacity: 0.1,
                            markerLineWidth: 1,
                            backgroundColor: false,
                            plotColor: "#54a7da",
                            markerFillColor: "#a9c0ce",
                            plotShadow: false,
                            navigatorEnabled: true
                        };
                    }
                });
        },
        // اضافه کردن تغییر وضعیت نمایش
        toggleMe(event) {
            $(event.target).parent().toggleClass('active');
        },
    },
};
</script>

<style >
@import 'https://static.tgju.org/components/tgju-fix/fix-custom.css?ver=09';
</style>
